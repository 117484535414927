import { RouteRecordRaw, RouterView } from 'vue-router';
import { h } from 'vue';
import router from '@/router';
import { StorageServices } from '@/services/StorageServices';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/',
            name: 'loggeduser layout',
            component: () => import("./views/loggeduser_layout.vue"),
            beforeEnter: (to, from) => {
                if(!StorageServices.getLoggedUser().identifier)
                    return router.push("/redirect?link=" + to.path);
            },
            children: [
                {
                    path: '/gallery',
                    name: 'gallery',
                    component: () => import("./views/gallery.vue"),
                },
                {
                    path: '/profile',
                    name: 'profile',
                    component: () => import("./views/profile.vue"),
                },
                {
                    path: '/challenge',
                    name: 'challenge',
                    component: () => import("./views/challenge.vue"),
                },
                {
                    path: '/challenge/list',
                    name: 'all challenges',
                    component: () => import("./views/challengeList.vue"),
                },
                {
                    path: '/challenge/detail/:challengeIdentifier',
                    name: 'challenge detail',
                    component: () => import("./views/challengeDetail.vue"),
                },                
            ]
        },
    ];
};