import { RouteRecordRaw, RouterView } from 'vue-router';
import { StorageServices } from '@/services/StorageServices';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/',
            name: 'hostess layout',
            component: () => import("./views/hostess_layout.vue"),
            beforeEnter: (to, from) => {
                if(!StorageServices.getLoggedUser().identifier)
                    return false
            },
            children: [
                {
                    path: '/dashboard',
                    name: 'dashboard',
                    component: () => import("./views/dashboard.vue"),
                },
                {
                    path: '/bracelet-scan',
                    name: 'bracelet scan',
                    component: () => import("./views/scanForBracelet.vue"),
                },
                {
                    path: '/welcome-kit-scan',
                    name: 'welcome-kit scan',
                    component: () => import("./views/scanForWelcomeKit.vue"),
                },
                {
                    path: '/gift-scan',
                    name: 'gift scan',
                    component: () => import("./views/scanForGift.vue"),
                },
                {
                    path: '/challenge-scan/:stageIdentifier',
                    name: 'challenge scan',
                    component: () => import("./views/scanForChallengeCompleted.vue"),
                },
            ]
        },
    ];
};