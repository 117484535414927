
import { Watch } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import { StorageServices } from './services/StorageServices';
import { AdminClient, LocalizationClient } from './services/Services';
import SpinnerContainer from '@/components/standard/spinnerContainer.vue';
import store from './store';
import * as OM from '@/Model';

@Options({
    components: {
        SpinnerContainer
    }
})
export default class App extends Vue {

    countdownDate: Date = new Date(2024, 5, 8, 9, 0);
    isCountdownEnded: boolean = false;

    selectedLanguage: string = "";
    availableLanguages: OM.TextIdentifier[] = [];

    isAppPublished: boolean = false;
    loaded: boolean = false;

    @Watch("$store.state.loggedUser.preferredCulture")
    languageChanged() {
        if(store.state.consts.localizedValues.length == 0)
            return;

        store.commit("updatedLanguage")
    }

    created() {
        Promise.all([
            LocalizationClient.getAppDictionary(),
            LocalizationClient.getEnabledLanguages(),
            AdminClient.getAppStatus()
        ])
        .then( x => {
            store.state.consts.localizedValues = x[0];
            store.state.consts.enabledLanguages = x[1];

            var language = this.$route.query.language ? this.$route.query.language.toString() : '';
            if(!language)
                language = store.state.loggedUser.preferredCulture;

            this.selectedLanguage = language ? language : 'it-IT';
            this.availableLanguages = this.$localizationService.getEnabledLanguageList();

            store.state.loggedUser = StorageServices.getLoggedUser();
            store.state.selectedLanguage = store.state.loggedUser.preferredCulture;

            this.isAppPublished = x[2];
            this.loaded = true;
        })
    }

    @Watch("$store.state.showSpinner")
    disableScroll(){
        var el = document.getElementById("body");
        
        if(el == null)
          return;
        
        if(store.state.showSpinner > 0)
            el.classList.add("overflow-hidden");
        else
            el.classList.remove("overflow-hidden");
    }
}
